import React, { useState } from 'react';
import './Detailcomponentcss/Detailform.css';
import { usePostLeadsMutation } from '../../Apihandlers/formApi';
const Detailform = (props) => {
 
  const [postLeads, { isLoading, isError, isSuccess }] = usePostLeadsMutation();
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  const links = window.location.href;
  
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    check_in: '',
    check_out: '',
    adult: 0,
    kids: 0,
    message: '',
    links: links
  });

  const incrementCount = (field, min = 1) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: Math.max(prevState[field] + 1, min)
    }));
  };

  const decrementCount = (field, min = 0) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: Math.max(prevState[field] - 1, min)
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const website_name = 'Kanatal Resorts';
  const indiaDateTime = new Date().toLocaleString('en-IN', {
    timeZone: 'Asia/Kolkata',
    hour12: false,            
    weekday: 'long',         
    year: 'numeric',          
    month: 'long',        
    day: 'numeric',       
    hour: '2-digit',         
    minute: '2-digit',       
    second: '2-digit'   
});

// Helper function to get today's date in YYYY-MM-DD format
const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Add leading zero to month
  const day = String(today.getDate()).padStart(2, '0');       // Add leading zero to day
  return `${year}-${month}-${day}`;
};



const formattedDateTime = indiaDateTime.replace(/,/g, ''); 

// Helper function to format the date
const formatDate = (date) => {
  if (!date) return '';
  const [year, month, day] = date.split('-');
  return `${day}-${month}-${year}`;
};

// Update the contact string
let contact = '';
if (formData['name'] !== '') contact += `Name:-${formData['name']}, `;
if (formData['mobile'] !== '') contact += `Mobile:-${formData['mobile']}, `;
if (formData['email'] !== '') contact += `Email:-${formData['email']}, `;
if (formData['check_in'] !== '') contact += `Check In:-${formatDate(formData['check_in'])}, `;
if (formData['check_out'] !== '') contact += `Check Out:-${formatDate(formData['check_out'])}, `;
if (formData['adult'] !== '') contact += `Adult:-${formData['adult']}, `;
if (formData['kids'] !== '') contact += `Kids:-${formData['kids']}, `;
if (formData['links'] !== '') contact += `Url:-${formData['links']}, `;
contact += `Date:-${formattedDateTime}`;
  const subject = '';
  const messages = formData['message'];
  
  const formdataObject = {
    website_name: website_name,
    contact: contact,
    subject: subject,
    message: messages
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setErrors({});

    try {
      await postLeads(formdataObject).unwrap();
      setMessage('Your message has been sent successfully!');
      setFormData({
        name: '',
        mobile: '',
        email: '',
        check_in: '',
        check_out: '',
        adult: 0,
        kids: 0,
        message: '',
        links: links
      });
    } catch (error) {
      setErrors(error.data || {});
      setMessage(error.message);
    }
  };

  const priceDisplays = props.price === null ? 'Call for price' : "₹ " + props.price +"/-**" +`<span>${props.night ?? ''}</span>`  ?? 'Call for price';

  return (
    <div className='detailform-container'>
      <form onSubmit={handleFormSubmit}>
      <h3 dangerouslySetInnerHTML={{__html:priceDisplays}}></h3>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
        />
        {errors.name && <span style={{ color: 'red', fontSize: '.8rem' }}>{errors.name}</span>}
        

        <input
          type="number"
          name="mobile"
          placeholder="Your Number"
          value={formData.mobile}
          onChange={handleChange}
        />
        {/* {errors.mobile && <span style={{ color: 'red', fontSize: '.8rem' }}>{errors.mobile}</span>} */}


        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
        />
        {/* {errors.email && <span style={{ color: 'red', fontSize: '.8rem' }}>{errors.email}</span>} */}
      
        
        <div className="check-in-out">
  <div>
    <label htmlFor="check_in">Check-In:</label>
    <input
      type="date"
      id="check_in"
      name="check_in"
      value={formData.check_in}
      onChange={handleChange}
      min={getTodayDate()} // Disable past dates
      placeholder="Check-In Date"
    />
  </div>
  <div>
    <label htmlFor="check_out">Check-Out:</label>
    <input
      type="date"
      id="check_out"
      name="check_out"
      value={formData.check_out}
      onChange={handleChange}
      min={formData.check_in || getTodayDate()} // Ensure Check-Out is after Check-In
      placeholder="Check-Out Date"
    />
  </div>
</div>


        <div className="form-adult-child">
          <label htmlFor="adults">
            Number of Adults:
            <br />
            <span>12+ Years</span>
          </label>
          <div style={{ display: 'flex', width: '30%' }}>
            <button type="button" onClick={() => decrementCount('adult', 1)}>-</button>
            <input
              type="text"
              id="adults"
              name="adult"
              onChange={handleChange}
              value={formData.adult}
              
            />
            <button type="button" onClick={() => incrementCount('adult', 1)}>+</button>
          </div>
        </div>

        <div className="form-adult-child">
          <label htmlFor="children">
            Number of Kids:
            <br />
            <span>6 - 12 Years</span>
          </label>
          <div style={{ display: 'flex', width: '30%' }}>
            <button type="button" onClick={() => decrementCount('kids', 0)}>-</button>
            <input
              type="text"
              id="children"
              name="kids"
              onChange={handleChange}
              value={formData.kids}
              
            />
            <button type="button" onClick={() => incrementCount('kids', 0)}>+</button>
          </div>
        </div>

        <textarea
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
        />

        <button
          type="submit"
          id="submit"
          name="submit"
          className="form-btn semibold"
          disabled={isLoading}
        >
          {isLoading ? 'Sending...' : 'Send Enquiry'}
        </button>

        {message && <div className={`alert ${isSuccess ? 'success' : 'error'}`}>{message}</div>}
      </form>
    </div>
  );
};

export default Detailform;
